<template>
  <div>
    <template v-if="coeff">
      <span style="color: #42a5f5">
        {{ item['bonus_new_gainful_percent'] | formatNumber('0.[00]') }}
      </span>
      *
      <span :class="{ green, red: !green }">
        {{ item['bonus_new_coeff'] | formatNumber('0.[00]') }}
      </span>
      =
      <span :id="id" :class="{ green: totalGreen, red: !totalGreen }">
        {{ coeff | formatNumber('0.[00]') }}
      </span>
    </template>
    <template v-else>-</template>
  </div>
</template>

<script>
  import uniqid from 'uniqid'

  import Popover from '../../../components/PopoverAlive'

  export default {
    name: 'BonusCoeff',
    props: ['item'],
    components: {
      Popover,
    },
    data() {
      return {
        id: uniqid(),
      }
    },
    computed: {
      coeff() {
        return this.item['bonus_new_gainful_percent'] * this.item['bonus_new_coeff']
      },
      green() {
        return this.item['bonus_new_coeff'] >= 1
      },
      totalGreen() {
        return this.coeff >= 1
      },
    },
  }
</script>

<style scoped></style>
