<template>
  <b-card class="m-0 panel" :class="{ 'is-loading': loading }">
    <b-table :fields="table.fields" :items="[data]" class="m-0">
      <template slot="count" slot-scope="data"> Отображено {{ count }} из {{ data.value || 0 }} </template>

      <template slot="hours" slot-scope="data"> {{ data.value || 0 | formatNumber }}ч </template>

      <template slot="worth" slot-scope="fieldData">
        <span id="summary-worth"> {{ fieldData.value || 0 | formatNumber }} &#8381; </span>
        <popover v-if="data.worth_per_hour" target="summary-worth">
          <div>
            <ul class="pl-15 m-0">
              <li v-for="employee in data.employees">
                <b>{{ employee.name }}</b
                ><br />
                {{ employee.hours | formatDuration(undefined, undefined, 'hours') }} *
                {{ employee.worth_per_hour | formatNumber }} &#8381; = {{ employee.worth | formatNumber }} &#8381;
              </li>
            </ul>
          </div>
          <div class="mt-5">
            <b>Итого:</b> {{ data.hours | formatDuration(undefined, undefined, 'hours') }}
            =
            {{ data.worth | formatNumber }} &#8381;
            <br />
            <b>Средняя себестоимость часа:</b>
            {{ data.worth_per_hour | formatNumber }} &#8381;
          </div>
        </popover>
      </template>

      <template slot="bonus_task_member_total" slot-scope="fieldData">
        <span id="summary-bonus_open_total" style="color: #ffbc0a">
          {{ data.bonus_open_total || 0 | formatNumber }} &#8381;
        </span>
        <span style="color: #ccc; margin: 0 5px">|</span>
        <span id="summary-bonus_closed_total" style="color: #6ead1f">
          {{ data.bonus_closed_total || 0 | formatNumber }} &#8381;
        </span>

        <popover v-for="(type, key) in ['bonus_open', 'bonus_closed']" :key="key" :target="`summary-${type}_total`">
          <div v-if="(data[`${type}_employee_statistic`] || []).length">
            <ul class="pl-15 m-0">
              <li v-for="employee in data[`${type}_employee_statistic`]">
                <b>{{ employee.name }}</b
                ><br />
                {{ employee.hours | formatDuration(undefined, undefined, 'hours') }} *
                {{ employee.premium | formatNumber }} &#8381; *
                <span :class="{ red: employee.premium_coeff <= 1, green: employee.premium_coeff > 1 }">
                  {{ employee.premium_coeff | formatNumber('0.[00]') }}
                </span>
                = {{ employee.total | formatNumber }} &#8381;
              </li>
            </ul>
          </div>
        </popover>
      </template>
    </b-table>
  </b-card>
</template>

<script>
  import api from '../../assets/js/api'

  import Popover from '../../components/PopoverAlive'

  import rightsMixins from '../../mixins/rights'

  export default {
    name: 'Summary',
    components: {
      Popover,
    },
    mixins: [rightsMixins],
    props: ['filters', 'count'],
    data() {
      return {
        loading: true,
        data: {},
        table: {
          fields: [
            {
              key: 'count',
              label: 'Количество задач',
            },
            {
              key: 'hours',
              label: 'Кол-во часов',
              class: 'text-center',
            },
            {
              key: 'worth',
              label: 'Себестоимость',
              class: 'text-center',
            },
            {
              key: 'bonus_task_member_total',
              label: 'Премии',
              class: 'text-center',
            },
          ],
        },
      }
    },
    methods: {
      update() {
        this.loading = true

        api.base
          .get({
            requestId: 'tasksSummary',
            path: 'tasks/summary',
            params: this.filters,
          })
          .then(result => {
            this.data = result
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },
    },
    created() {
      this.update()
      this.cleanRolesElements(this.table.fields)
    },
    watch: {
      $route() {
        this.update()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card {
    border-top: 1px solid #e0e0e0 !important;
    margin-bottom: 2rem !important;
  }

  /deep/ {
    td,
    th {
      border: 0 !important;
    }
  }
</style>
