<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <div slot="list" slot-scope="data">
      <b-card class="m-0">
        <b-table
          :items="items"
          :fields="table.fields"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          no-local-sorting
          responsive
        >
          <template slot="visible" slot-scope="data">
            <c-switch
              type="default"
              size="xs"
              class="center-block align-middle mb-0"
              :checked="!!data.value"
              @change="changeVisible(data.item, $event)"
            ></c-switch>
          </template>

          <template slot="id" slot-scope="data">
            <span :id="'id-row_' + data.index">
              {{ data.value }}
            </span>

            <popover :target="'id-row_' + data.index">
              <div v-if="data.item.extra.td_id">
                <b>TimeDoctor ID:</b>
                <a :href="data.item.tdLink || '#'" target="_blank">
                  {{ data.item.extra.td_id }}
                </a>
              </div>
              <div v-if="data.item.extra.asana_hook_id">
                <b>Asana HID:</b>
                {{ data.item.extra.asana_hook_id }}
              </div>
            </popover>
          </template>

          <template slot="responsible" slot-scope="data">
            <responsible :responsible="data.value"></responsible>
          </template>

          <template slot="name" slot-scope="data">
            <fa icon="pencil-alt" size="sm" class="mr-10 edit-icon" @click="modalSwitch(data.item)" />
            <b-link :id="'name-row_' + data.index" :to="'/tasks/' + data.item.id">
              {{ (data.value || 'Без названия') | slicer(50) }}
            </b-link>

            <popover :target="'name-row_' + data.index">
              <div v-if="data.item.parent">
                <b>Родительская:</b>
                <b-link :href="'/tasks/list?filters[tasks][id]=' + data.item.parent.id" target="_blank">
                  {{ data.item.parent.name | slicer(30) }}
                </b-link>
              </div>
              <div v-if="data.item.extra.asana_task_id">
                <b>Asana:</b>
                <a :href="'https://app.asana.com/0/0/' + data.item.extra.asana_task_id + '/f'" target="_blank">
                  перейти
                </a>
              </div>
            </popover>
          </template>

          <template slot="project" slot-scope="data">
            <b-link
              v-if="data.item.project"
              :to="
                '/projects/list?filters[projects][id]=' +
                data.value.id +
                '&filters[projects][preset]=allActiveAndArchive'
              "
              target="_blank"
            >
              {{ (data.value.name || 'Без названия') | slicer(30) }}
            </b-link>
            <template v-else>-</template>
          </template>

          <template slot="amocrm_lead_id" slot-scope="data">
            <a
              v-if="data.item.extra.amocrm_lead_id"
              :href="'https://rocketcrm.amocrm.ru/leads/detail/' + data.item.extra.amocrm_lead_id"
              target="_blank"
              >Перейти</a
            >
            <template v-else>-</template>
          </template>

          <template slot="single_hours" slot-scope="data">
            <progress-bar
              :id="'single_hours-row_' + data.index"
              :max="150"
              :value="data.item.time_mastering_percent || 0"
              reverse
            >
              {{ (data.item.time / 3600) | formatDuration(undefined, undefined, 'hours') }}
              <template v-if="data.item.planHours">
                / {{ data.item.planHours }}ч ({{ data.item.time_mastering_percent | formatNumber }}%)
              </template>
            </progress-bar>
            <popover :target="'single_hours-row_' + data.index">
              <div v-if="data.item.planHours">
                <b>{{ data.item.time / 3600 <= data.item.planHours ? 'Остаток' : 'Просрочка' }}:</b>
                {{ Math.abs((data.item.planHours - data.item.time / 3600) * 60 * 60) | formatDuration }}
              </div>
              <div v-if="data.item.time_detail">
                <b>Учасники:</b>
                <ul class="pl-15 m-0">
                  <li v-for="employee in data.item.time_detail">
                    {{ employee.name }} ({{ employee.time | formatDuration }})<br />
                    Последнее касание: {{ employee.last_touch | formatDate }}
                  </li>
                </ul>
              </div>
            </popover>
          </template>

          <template slot="is_bonus_task" slot-scope="data">
            <b-badge
              :variant="data.value ? 'success' : 'secondary'"
              :id="'is_bonus_task-row_' + data.index"
              style="font-size: 10px"
            >
              {{ data.value ? 'Премиум' : 'Не премиум' }}
            </b-badge>
            <popover :target="'is_bonus_task-row_' + data.index">
              <div v-if="!data.item['bonus_new_gainful_percent']">
                <b>Задача находится не в премиальном проекте!</b>
              </div>
              <div v-if="!data.item['bonus_new_hours_plan']">
                <b>У задачи нет планового времени!</b>
              </div>
            </popover>
          </template>

          <template slot="bonus_new_total" slot-scope="data">
            <span :id="'bonus_new_total-row_' + data.index"> {{ data.value | formatNumber }} &#8381; </span>

            <popover :target="'bonus_new_total-row_' + data.index">
              <div>
                <b>Расход часов:</b>
                {{ data.item.bonus_new_hours_fact | formatDuration(undefined, undefined, 'hours') }}
                <template v-if="data.item.bonus_new_hours_plan">
                  / {{ data.item.bonus_new_hours_plan | formatDuration(undefined, undefined, 'hours') }} ({{
                    data.item.bonus_new_hours_mastering_percent | formatNumber
                  }}%)
                </template>
              </div>

              <template v-if="data.item.bonus_new_hours_plan">
                <!--<div v-if="data.item.bonus_new_hours_saving_percent" class="green">-->
                <!--<b>Экономия:</b>-->
                <!--{{data.item.bonus_new_hours_saving_percent | formatNumber}}%-->
                <!--</div>-->
                <div v-if="data.item.bonus_new_hours_overdue_percent" class="red">
                  <b>Превышение:</b>
                  {{ data.item.bonus_new_hours_overdue_percent | formatNumber }}%
                </div>
                <div v-if="data.item.bonus_new_date_penalty_percent" class="red">
                  <b>Просрочка:</b>
                  {{ data.item.bonus_new_overdue_days }}д *
                  {{ data.item.bonus_new_date_penalty_coeff | formatNumber('0.[00]') }}% =
                  {{ data.item.bonus_new_date_penalty_percent | formatNumber }}% (не более
                  {{ data.item.bonus_new_date_max_penalty_percent | formatNumber }}%)
                </div>
                <div class="mt-10">
                  <b>Коэффициент:</b>
                  (100%
                  <!--<span v-if="data.item.bonus_new_hours_saving_percent" class="green">-->
                  <!--+ {{data.item.bonus_new_hours_saving_percent | formatNumber}}%-->
                  <!--</span>-->
                  <span v-if="data.item.bonus_new_hours_overdue_percent_calc" class="red">
                    - {{ data.item.bonus_new_hours_overdue_percent_calc | formatNumber }}%
                  </span>
                  <span v-if="data.item.bonus_new_date_penalty_percent" class="red">
                    - {{ data.item.bonus_new_date_penalty_percent | formatNumber }}%
                  </span>
                  ) / 100 =
                  <span :class="{ red: data.item.bonus_new_coeff <= 1, green: data.item.bonus_new_coeff > 1 }">
                    {{ data.item.bonus_new_coeff | formatNumber('0.[00]') }}
                  </span>
                </div>
                <div>
                  <b>Коэффициент проекта:</b>
                  <span style="color: #42a5f5">
                    {{ data.item.bonus_new_gainful_percent | formatNumber('0.[00]') }}
                  </span>
                </div>
                <div v-if="(data.item.bonus_new_employee_statistic || []).length" class="mt-10">
                  <ul class="pl-15 m-0">
                    <li
                      v-for="employee in [
                        ...data.item.bonus_new_employee_statistic,
                        ...Object.assign({}, data.item.bonus_new_total_statistic, { _all: true }),
                      ]"
                      class="mt-2"
                    >
                      <b>{{ employee._all ? 'Все вместе' : employee.name }}</b
                      ><br />
                      {{ employee.hours | formatDuration(undefined, undefined, 'hours') }} *
                      {{ employee.premium | formatNumber }} &#8381; *
                      <span style="color: #42a5f5">
                        {{ data.item.bonus_new_gainful_percent | formatNumber('0.[00]') }}
                      </span>
                      *
                      <span :class="{ red: employee.premium_coeff <= 1, green: employee.premium_coeff > 1 }">
                        {{ employee.premium_coeff | formatNumber('0.[00]') }}
                      </span>
                      = {{ employee.total | formatNumber }} &#8381;
                    </li>
                  </ul>
                </div>
              </template>
            </popover>
          </template>

          <template slot="days_in_work" slot-scope="data">
            <progress-bar
              :id="'days_in_work-row_' + data.index"
              :value="data.item.days_in_work_percent || 0"
              :max="150"
              reverse
            >
              {{ data.value }}
              <template v-if="data.item.days_in_production_plan">
                / {{ data.item.days_in_production_plan }} дн. ({{ data.item.days_in_work_percent | formatNumber }}%)
              </template>
              <template v-else>дн.</template>
            </progress-bar>

            <popover :target="'days_in_work-row_' + data.index">
              <div v-if="data.item.days_in_production_plan" class="mb-5">
                <b>Дней до закрытия:</b>
                {{ data.item.days_to_deadline }}
              </div>
              <div>
                <b>Передан в производство:</b>
                {{ data.item.created_at | formatDate }}
              </div>
              <div>
                <b>Закрытие, план:</b>
                <template v-if="data.item.deadline_at">
                  {{ data.item.deadline_at | formatDate }}
                </template>
                <template v-else>-</template>
              </div>
              <div>
                <b>Закрытие, факт:</b>
                <template v-if="data.item.closed_at">
                  {{ data.item.closed_at | formatDate }}
                </template>
                <template>-</template>
              </div>
            </popover>
          </template>

          <template slot="worth" slot-scope="data">
            <span :id="'worth-row_' + data.index"> {{ data.value | formatNumber }} &#8381; </span>
            <popover :target="'worth-row_' + data.index">
              <div>
                <b>Общее кол-во часов:</b>
                {{ data.item.single_hours | formatDuration(undefined, undefined, 'hours') }}
              </div>
              <div>
                <b>Средняя стоимость часа:</b>
                {{ data.item.average_hour_worth | formatNumber }} &#8381;
              </div>
              <div class="mt-5">
                <b>Участники:</b>
                <ul class="pl-15 m-0">
                  <li v-for="employee in data.item.employee_statistic">
                    {{ employee.name }}<br />
                    {{ employee.seconds | formatDuration }} * {{ employee.worth_per_hour | formatNumber }} &#8381; =
                    {{ employee.worth | formatNumber }} &#8381;
                  </li>
                </ul>
              </div>
            </popover>
          </template>

          <bonus-coeff slot="bonus_coeff" slot-scope="{ item }" :item="item" />

          <!--Header -->

          <template slot="HEAD_is_bonus_task">
            <fa size="sm" icon="dollar-sign"></fa>
          </template>
        </b-table>
        <edit-task :source="modal.item" :module="module">
          <b-button
            v-if="modal.item"
            variant="outline-primary"
            v-b-modal.transfer-time
            style="position: absolute; bottom: -40px"
          >
            Перенести интервалы
          </b-button>
        </edit-task>
        <transfer-modal v-if="modal.item" id="transfer-time" :item="modal.item" />
      </b-card>
      <Summary :filters="module.getters.query" :count="items.length" />
    </div>
  </list>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { getUsersDropdown } from '../../assets/js/common'

  import List from '../../components/List'
  import Popover from '../../components/PopoverAlive'
  import Responsible from '../../components/Responsible'
  import cSwitch from '../../components/Switch'
  import EditTask from './EditTask'
  import TransferModal from './TransferModal'
  import ProgressBar from '../../components/ProgressBar'

  import BonusCoeff from './common/BonusCoeff'

  import Summary from './Summary'

  import entityMixins from '../../mixins/entity'

  export default {
    name: 'TasksList',
    components: {
      TransferModal,
      List,
      EditTask,
      cSwitch,
      Responsible,
      ProgressBar,
      Popover,
      Summary,
      BonusCoeff,
    },
    mixins: [entityMixins],
    data() {
      return {
        table: {
          fields: [
            {
              key: 'visible',
              label: 'Актив.',
              class: 'text-center',
            },
            {
              key: 'id',
              label: 'ID',
              class: 'text-center',
            },
            {
              key: 'responsible',
              label: '&nbsp;',
              class: 'text-center',
            },
            {
              key: 'name',
              label: 'Название',
              sortable: true,
              class: '',
            },
            {
              key: 'project',
              label: 'Проект',
              sortable: true,
            },
            {
              key: 'is_bonus_task',
              label: 'Премиальность',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'bonus_coeff',
              label: 'Прем. коэф',
              class: 'text-center',
            },
            {
              key: 'bonus_new_total',
              label: 'Премия',
              class: 'text-center',
            },
            {
              key: 'single_hours',
              label: 'Время',
              class: 'text-center progress-col',
            },
            {
              key: 'days_in_work',
              label: 'Сроки',
              class: 'text-center progress-col',
            },
            {
              key: 'worth',
              label: 'Себестоимость',
              class: 'text-center progress-col',
            },
          ],
        },
        filters: [
          {
            label: 'Быстрый поиск',
            name: 'name',
            width: 2,
          },
          {
            name: 'project_id',
            label: 'Проект',
            type: 'select',
            ajax: {
              entity: 'filter/projects',
            },
            width: 2,
          },
          {
            id: 'responsible',
            name: 'responsible',
            label: 'Ответственный',
            type: 'select',
            width: 2,
          },
          {
            id: 'followers',
            name: 'followers',
            label: 'Участники',
            type: 'select',
            multiple: true,
            width: 2,
          },
          {
            label: 'Пресеты',
            name: 'preset',
            type: 'select',
            width: 2,
            items: [
              {
                name: 'Все в работе',
                value: 'allOpened',
              },
              {
                name: 'В работе и завершены',
                value: 'allOpenedAndClosed',
              },
              {
                name: 'Все завершены',
                value: 'allClosed',
              },
            ],
          },
          {
            label: 'Тип',
            name: 'bonus_type',
            type: 'select',
            width: 2,
            items: [
              {
                name: 'Все',
                value: '',
              },
              {
                name: 'Внутренние',
                value: 'inside',
              },
              {
                name: 'Проектные',
                value: 'outside',
              },
            ],
          },
          {
            name: 'create_date',
            type: 'date',
            range: true,
            legend: 'Дата создания',
            width: 3,
          },
          {
            name: 'close_date',
            type: 'date',
            range: true,
            legend: 'Дата закрытия',
            width: 3,
          },
          {
            name: 'deadline_date',
            type: 'date',
            range: true,
            legend: 'Дэдлайн',
            width: 3,
          },
          {
            label: 'Есть дэдлайн',
            name: 'isset_deadline',
            type: 'checkbox',
            width: 1,
          },
          {
            label: 'План. время',
            name: 'isset_estimate',
            type: 'checkbox',
            width: 1,
          },
          {
            label: 'Премиум',
            name: 'billable',
            type: 'checkbox',
            width: 1,
          },
        ],
      }
    },
    beforeRouteEnter(to, from, next) {
      getUsersDropdown().then(items => {
        next(vm => {
          vm.filters
            .filter(filter => {
              return ['responsible', 'followers'].indexOf(filter.name) !== -1
            })
            .forEach(f => (f.items = cloneDeep(items)))

          vm.$store.commit('currentView/setActions', [
            {
              label: 'Создать',
              icon: 'plus-square',
              onClick() {
                vm.modalSwitch(null)
              },
            },
          ])
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    th.progress-col {
      width: 150px !important;
    }
  }
</style>
