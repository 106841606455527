<template>
  <b-modal
    :id="id"
    title="Перенести время"
    ok-only
    ok-title="Перенести"
    :ok-disabled="!form.toId || processing"
    @ok.prevent="onSubmit"
    @shown="resetForm"
  >
    <b-alert show variant="warning">
      <b>Внимание!</b> В задачу-источник будут перенесены все временные интервалы
    </b-alert>
    <b-form @submit.prevent.stop="onSubmit">
      <b-form-group label="ID задачи-источника">
        <b-input disabled :value="form.fromId" />
      </b-form-group>
      <b-form-group label="ID задачи-приёмника">
        <b-input placeholder="ERP ID" type="number" v-model="form.toId" trim />
      </b-form-group>
      <input type="submit" style="position: absolute; visibility: hidden" />
    </b-form>
  </b-modal>
</template>

<script>
  import { api } from '../../assets/js/api'
  import { notifications } from '../../assets/js/notification'

  export default {
    name: 'TransferModal',
    props: ['id', 'item'],
    data() {
      return {
        form: null,
        processing: false,
      }
    },
    methods: {
      resetForm() {
        this.form = {
          fromId: this.item.id,
          toId: null,
          confirm: 'Y',
        }
      },
      async onSubmit() {
        try {
          this.processing = true

          let {
            data: { result },
          } = await api.http.get('/tasks/custom/td/transfer-id', {
            params: this.form,
          })

          if (!result) {
            return notifications['error']('Произошла неизвестная ошибка', 'Ошибка')
          }

          notifications['success']('Интервалы перенесены', 'Успешно')
          this.$root.$emit('bv::hide::modal', 'transfer-time')
        } finally {
          this.processing = false
        }
      },
    },
    created() {
      this.resetForm()
    },
  }
</script>
