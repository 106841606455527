import { render, staticRenderFns } from "./BonusCoeff.vue?vue&type=template&id=13633448&scoped=true&"
import script from "./BonusCoeff.vue?vue&type=script&lang=js&"
export * from "./BonusCoeff.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13633448",
  null
  
)

export default component.exports